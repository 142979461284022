<template>
    <div class="playback-options-col">
        <div class="sounds-available hidden-xs">
            <flip-counter v-bind:sounds-available="soundsAvailable"></flip-counter>
        </div>
        <div class="playback-options-button hidden-xs">

            <img class="playback-options-icon-text" src="/static/images/loops remaining.svg" />
        </div>

        <hr />


        <div class="playback-options-button" v-bind:class="{active: beat}">
            <a href="#" v-on:click.prevent="clickBeat">
                <img class="playback-options-icon" src="/static/images/drums.svg" />
                <img class="playback-options-icon-text" src="/static/images/add beat.svg" />
            </a>
        </div>

        <hr />

        <div class="playback-options-button" v-bind:class="{active: kick}">
            <a href="#" v-on:click.prevent="clickKick">
                <img class="playback-options-icon" src="/static/images/metronome.svg" />
                <img class="playback-options-icon-text" src="/static/images/add klick.svg" />
            </a>
        </div>

        <hr />

        <div class="playback-options-button">
            <a href="/">
                <img class="playback-options-icon" src="/static/images/reload Button.svg" />
                <img class="playback-options-icon-text" src="/static/images/reload loops.svg" />
            </a>
        </div>

        <hr />

    </div>
</template>

<script>
import { EventBus } from './eventbus.js';
import FlipCounter from './FlipCounter.vue';

export default {
  name: 'playback-options',
  components: {
    'flip-counter': FlipCounter
  },
  props: [
    'soundsAvailable',
    'loop',
    'kick',
    'beat',
    'bpm'
  ],
  data() {
    return {
      message: 'I get rendered in the element with the id #place-on-the-page!'
    }
  },
  methods: {
    clickKick() {
      EventBus.$emit('kick');
    },
    clickBeat() {
      EventBus.$emit('beat');
    }
  }
}

</script>