<template>
  <div id="app">
    <login-button :credits="credits" :csrftoken="csrfToken"></login-button>
    <div class="blockling-container">
      <playback-options :sounds-available="soundsAvailable"
                        :loop="loop" :kick="kick" :beat="beat" :bpm="bpm"></playback-options>
      <div class="row" v-if="isLoading">
        <div class="spinner"></div>
      </div>
      <div v-else="isLoading" class="blockling-col">
        <blockling v-for="(blockling, index) in blocklings"
                   :color="blockling.color"
                   :icon="blockling.icon"
                   :link="blockling.link"
                   :text="blockling.text"
                   :loop-url="blockling.loop_url"
                   :kick-url="blockling.kick_url"
                   :beat-url="blockling.beat_url"
                   :play-mode="playmode"
                   :sold="blockling.sold"
                   :uuid="blockling.uuid"
                   :animated="blockling.animated"
                   :credits="credits"
                   :variant="blockling.variant"
                   :bpm="blockling.bpm"
                   :index="index"
                   :key="blockling.id"
                   :opened="blockling.opened">
        </blockling>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Blockling from './Blockling.vue';
import PlaybackOptions from './PlaybackOptions.vue';
import LoginButton from './LoginButton.vue';

// Import the EventBus
import { EventBus } from './eventbus.js';

export default {
  name: 'app',
  components: {
    'login-button': LoginButton,
    'blockling': Blockling,
    'playback-options': PlaybackOptions
  },
  data () {
    return {
      loop: true,
      kick: false,
      beat: false,
      bpm: 0,
      msg: 'Welcome to Your Vue.js App',
      blocklings: [],
      sounds: [],
      username: window.user_name,
      isLoading: true,
      last_event: 0,
      credits: 1337,
      palette: [],
      width: 0,
      height: 0,
      csrfToken: null,
      soundsAvailable: 0,
      useSeed: false
    }
  },
  created: function () {
    this.soundsAvailable = window.sounds_available;
    this.credits = this.$credits;
    this.csrfToken = window.csrfToken;
    this.useSeed = window.use_seed;
    this.refreshSounds();
    setInterval(() => {
      this.pollEvents();
    }, 5000);
    EventBus.$on('sold', uuid => {
      this.soldSound(uuid);
    });
    EventBus.$on('kick', () => {
      if (this.kick === true) {
        this.toggleLoop();
      }
      else {
        this.toggleKick();
      }
    });
    EventBus.$on('beat', () => {
      if (this.beat === true) {
        this.toggleLoop();
      }
      else {
        this.toggleBeat();
      }
    });
    EventBus.$on('bpm', (bpms) => {
      this.setBpms(bpms);
    });
  },
  computed: {
    playmode() {
      if (this.kick === true) {
        return 'kick';
      }
      if (this.beat === true) {
        return 'beat';
      }
      return 'loop';
    }
  },
  methods: {
      toggleLoop() {
        this.loop = true;
        this.kick = false;
        this.beat = false;
      },
      toggleKick() {
        this.beat = false;
        this.kick = true;
        this.loop = false;
      },
      toggleBeat() {
        this.loop = false;
        this.kick = false;
        this.beat = true;
      },
      setBpms(bpms) {
        this.bpm = bpms;
      },
      pollEvents() {
          axios.get(`/shop_events/?last_event=${this.last_event}`)
          .then((response) => {
              let block_sold = false;
              console.log(response.data);
              if (this.soundsAvailable !== response.data['sounds_available']) {
                this.soundsAvailable = response.data['sounds_available'];
              }
              for(let event of response.data['events']) {
                  // ignore the event if we have seen it already.
                  if (event.id <= this.last_event) {
                    continue;
                  }
                  this.last_event = event.id;
                  for(let block of this.blocklings) {
                      if (block.uuid === event.uuid) {
                          console.log("UUID gefunden");
                          block.sold = true;
                          block_sold = true;
                      }
                  }
              }
              if (block_sold === true) {
                iziToast.show({
                    theme: 'dark',
                    title: 'Uhoh',
                    position: 'topRight',
                    message: 'Sounds were sold. Hurry up!'
                });
              }
          })
          .catch(function (error) {
              console.error(error);
          });
      },
      refreshSounds() {
          let url = '/random_sounds/';
          if (this.useSeed === true) {
            url = url + '?use_seed=1';
            this.useSeed = false;
          }
          axios.get(url, {
              params: {}
          })
          .then((response) => {
            this.sounds = response.data;
            this.fillSounds();
          })
          .catch(function (error) {
              console.log(error);
          });
      },
      fillSounds() {
          // let palette = ['#993381', '#277B9D', '#46A647', '#A68439', '#9D3D48', '#233896'];
          const sm = window.matchMedia( "(min-width: 768px)" );
          const lg = window.matchMedia( "(min-width: 1200px)" );
          let login_text = 'login';
          if (window.username !== '') {
              login_text = window.username;
          }
          let height = 10;
          let width = 16;
          if (sm.matches && !lg.matches) {
              height = 10;
              width = 16;
          }
          else if (lg.matches && lg.matches) {
              height = 10;
              width = 16;
          }
          else {
              height =32;
              width = 5;
          }
          this.width = width;
          this.height = height;

          axios.get(`/palette/?width=${width}&height=${height}`)
          .then((response) => {
            this.palette = response.data.pixels;
            this.renderSounds();
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      renderSounds() {
        let blocks = [];
        for (let i = 0; i < this.sounds.length; i++) {
          let block = {};
          block.color = this.palette[i];
          // block.sound = '/static/audio/' + this.sounds[i].filename;
          block.loop_url = this.sounds[i].loop_url;
          block.kick_url = this.sounds[i].kick_url;
          block.beat_url = this.sounds[i].beat_url;
          block.sold = false;
          block.opened = this.sounds[i].opened;
          block.animated = '';
          block.uuid = this.sounds[i].uuid;
          block.bpm = this.sounds[i].bpm;
          blocks.push(block);
          // console.log(block);
        }
        this.bounceBlockling(24, 'wobble', 1000);
        this.bounceBlockling(34, 'rubberBand', 5000);

        this.blocklings = blocks;
        this.isLoading = false;
        console.log(blocks);
      },

      bounceBlockling(index, animation, timeOut) {
        // animation can be anything from https://daneden.github.io/animate.css/
        window.setTimeout(() => {
            this.blocklings[index].animated = animation;
            window.setTimeout(() => {
              this.blocklings[index].animated = '';
            }, 1000)
        }, timeOut);
      },
      soldSound(uuid) {
        this.credits = this.credits - 1;
        for(let block of this.blocklings) {
          if (block.uuid === uuid) {
            block.sold = true;
          }
        }
      }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
