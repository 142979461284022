<template>
  <div v-if="sold"
       class="blockling animated hinge" v-bind:style="{ backgroundColor: color }">
    <a class="sold" href="#">SOLD</a>
  </div>
  <div v-else
       v-bind:class="blockClass"
       v-bind:style="{ backgroundColor: color}"
       v-on:click.stop="openBlockling">
    <audio ref="myplayer" autoplay>
      <source v-bind:src="soundUrl" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
    <div v-if="blocklingIsOpen" class="blockling-open animated zoomIn" id="hideme"
         v-bind:style="{backgroundColor: color, opacity: 1}"
         @click.stop="closeBlockling">
      <div class="blockling-bpm-wrapper">
        <img class="blockling-bpm" v-for="digit in digits" :src="digit" />
        <img class="blockling-bpm-unit" src="/static/images/%23IB%20Website%20bpm.svg"/>
      </div>
      <a href="#" @click.stop="playPause" class="play-button">
      <div class="play-button-wrapper">

          <img v-if="playing" src="/static/images/%23IB%20Website%20PAUSE.svg" />
          <img v-else src="/static/images/%23IB%20Website%20PLAY.svg" />

      </div>
         </a>
      <!--<p class="snippet-name">#snippet1</p>-->
      <a class="download" href="#" v-on:click.stop.prevent="openShop">
        <img src="/static/images/catchme.svg" />
      </a>
    </div>

    <div v-if="shopIsOpen" class="blockling-open animated rotateInDownRight" id="hideme2"
      :style="{backgroundColor: color, opacity: 1}">
        <div v-if="isLoggedIn" class="shop">
          <div>{{ credits }} tokens left</div>

          <a class="order-btn" href="#" v-on:click.stop.prevent="downloadSound"
                v-if=" isLoggedIn && !waitingForDownloadLink ">download<span class="hideme-sm"> now</span></a>
          <span class="order-btn" v-on:click.stop.prevent="downloadSound"
                v-if=" isLoggedIn && waitingForDownloadLink ">{{ downloadLinkText }}</span>

          <a class="download goback" href="#" v-on:click.stop="closeShop">
            <img src="/static/images/%23IB%20Website%20go%20back.svg" />
          </a>


        </div>
        <div v-else="" class="shop">
          <p>Please login<span class="hideme-sm"> to download.</span></p>
          <p><a v-bind:href="registerLink" style="background: #000; padding: 2px;">register<span class="hideme-sm">
            now …</span></a></p>


          <a class="download goback" href="#" v-on:click.stop="closeShop">
            <img src="/static/images/%23IB%20Website%20go%20back.svg" />
          </a>
        </div>

    </div>
    <div v-else="" class="">
      <div class="login-credits" v-if="variant === 'login'">
          {{ credits }} ¢
      </div>
      <a v-if="icon" v-bind:href="link">
        <i v-bind:class="icon"></i>
        <p>{{ text }}</p></a>
    </div>
  </div>
</template>

<script>
// Import the EventBus we just created.
import { EventBus } from './eventbus.js';
import axios from 'axios';

axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default {
  name: 'blockling',
  props: {
    color: {
      type: String,
      required: true
    },
    icon: {},
    text: {
      type: String
    },
    link: {
      type: String
    },
    loopUrl: {
      type: String
    },
    kickUrl: {
      type: String
    },
    beatUrl: {
      type: String
    },
    playMode: {
      type: String
    },
    sold: {
      type: Boolean
    },
    animated: {
      type: String
    },
    credits: {
      default: 0,
      type: Number
    },
    uuid: {
      type: String
    },
    bpm: {
      type: Number
    },
    variant: {
      type: String
    },
    index: {
      type: Number
    },
    // us this only to "pre-open" one of the blocklings
    opened: {
      type: Boolean,
      required: false
    }
  },
  data: function() {
    return {
      blocklingIsOpen: false,
      shopIsOpen: false,
      waitingForDownloadLink: false,
      downloadLinkText: 'please wait …',
      playing: true,
    };
  },
  watch: {
    playMode(old_val, new_val) {
      // Change the sound when play mode is changed
      let el = this.$refs.myplayer;
      el.currentTime = 0;
      el.load();
      //$(el).animate()
      // TODO: https://stackoverflow.com/questions/7451508/html5-audio-playback-with-fade-in-and-fade-out
      this.playing = true;
      el.play();
    }
  },
  computed: {
    digits: function () {
      let bpm_str = this.bpm.toString().padStart(3, '0');
      let res = [];
      for (let digit of bpm_str) {
        res.push('/static/images/%23IB%20Website%20' + digit + '.svg');
      }
      return res;
    },
    registerLink() {
      return '/accounts/signup/?sound=' + this.uuid + '&next=%2F%3Flogged_in%3D1';
    },
    audioId() {
      return 'audio-' + this.index;
    },
    soundUrl() {
      if (!this.blocklingIsOpen) {
        return '';
      }
      if (this.playMode === 'beat') {
        return this.beatUrl;
      }
      if (this.playMode === 'kick') {
        return this.kickUrl;
      }
      return this.loopUrl;
    },
    isLastBlock() {
      return (this.row === 9 && this.col === 14 );
    },
    playerId() {
      return('player-' + this.id);
    },
    blockClass() {
      if (this.animated !== '') {
          console.log("23:" + this.animated);
          return ['blockling', 'animated', this.animated]
      }
      else {
          return ['blockling'];
      }
    },
    isLoggedIn() {
      if(window.username === '' || window.username === undefined) {
        return false;
      }
      else {
        return true;
      }
    },
    username() {
      return window.username;
    },
  },
  created: function () {
    if (this.opened === true) {
      console.log(this.index + ' is open');
      setTimeout(() => this.openBlockling(null), 200);
    }
    EventBus.$on('opened', index => {
      if (index !== this.index) {
        this.closeBlockling();
      }
    });
  },
  methods: {
    refresh(event) {
      console.log("refresh");
      // do nothing
    },
    onEnded() {
      this.playing = false;
      this.$refs.myplayer.currentTime = 0;
    },
    playPause(event) {
      let el = this.$refs.myplayer;
      if (this.playing) {
        el.pause();
        this.playing = false;
      }
      else {
        el.play();
        this.playing = true;
      }
    },
    openBlockling(event) {
      EventBus.$emit('opened', this.index);
      if (this.icon) {
          return;
      }
      EventBus.$emit('bpm', this.bpm);
      this.blocklingIsOpen = true;
      this.$refs.myplayer.load();
      this.$refs.myplayer.addEventListener('ended', this.onEnded, true);
      this.$refs.myplayer.play();
    },
    closeBlockling(event) {
      this.$refs.myplayer.pause();
      this.blocklingIsOpen = false;
      this.shopIsOpen = false;
      this.$refs.myplayer.removeEventListener('ended', this.onEnded, true);
    },
    openShop() {
      this.shopIsOpen = true;
    },
    closeShop() {
      this.shopIsOpen = false;
    },
    downloadSound() {
      this.waitingForDownloadLink = true;
      axios.put(`/orders/${this.uuid}/`, {
        uuid: this.uuid,
        bought_by: window.username,
      })
      .then((response) => {
        let url = response.data.download_url;
        setTimeout(() => {
          downloadFile(url);
        }, 1000);
        EventBus.$emit('sold', this.uuid);
      })
      .catch(() => {
          this.downloadLinkText = 'error!'
      })
    }
  }
}
 </script>

<style>

</style>