import Vue from 'vue'
import App from './App.vue'
import iziToast from 'izitoast'
import PortalVue from 'portal-vue';
import jQuery from "jquery";
window.jQuery = window.$ = jQuery

Vue.use(PortalVue);

Vue.prototype.$loggedin = window.loggedin;
Vue.prototype.$credits = window.credits;
Vue.prototype.$csrftoken = document.getElementById('fake-login-form').children[0].getAttribute('value');

new Vue({
  el: '#app',
  render: h => h(App)
});
