<template>
  <div class="clock" ref="counter" style="margin: 2em 0 0.5vw 0"></div>
</template>

<script>
export default {
  name: 'flip-counter',
  props: [
      'soundsAvailable'
  ],
  data() {
    return {
      clock: null
    }
  },
  mounted: function () {
    // Instantiate a counter
    this.clock = new FlipClock($(this.$refs.counter), {
      clockFace: 'Counter',
      autoStart: false,
      minimumDigits: 6
    });

    this.clock.setValue(this.soundsAvailable);
  },
  watch: {
    soundsAvailable(newVal, oldVal) {
      this.clock.setValue(newVal);
    }
  }
}
</script>