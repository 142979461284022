<template>
  <portal target-el="#login-button">
    <div v-if="loggedin" class="title-menu-item">
      <div class="login-button" style="display: inline-block;">
        <a href="/users/~redirect/">
          <img id="login-button-image" alt="login" src="/static/images/profile.svg"/>
        </a>
      </div>
      <div class="login-credits-wrapper">
        <div class="login-credits" :title="creditTitle">
          <img class="credits-digit" v-for="digit in digits" :src="digit"/>
          <img class="credits-digits-unit" src="/static/images/tokens.svg"/>
        </div>
      </div>
    </div>

    <div v-else id="login-button">
      <a href="#" @click="onLoginClick">
        <img alt="login" src="/static/images/%23IB%20Website%20login.svg"/>
      </a>
      <div v-if="formVisible" id="login-form">
        <form action="/accounts/login/?next=%2F%3Flogged_in%3D1" method="POST">
          <input type="hidden" name="csrfmiddlewaretoken" :value="csrftoken">
          <div>
            <input placeholder="email" required="true"
                   class="login-form-input form-control textinput" type="email" name="login"/>
          </div>
          <div>
            <input placeholder="password" required="true"
                   class="login-form-input form-control textinput" type="password" name="password"/>
          </div>
          <button type="submit"
                  id="login-submit"><img src="/static/images/%23IB%20Website%20login.svg"
                                         alt="login"
                                         title="Log in with these credentials."></button>
        </form>
        <div style="margin-left: 28px; margin-top: 20px;">No account yet?&nbsp;&nbsp;
          <a href="/accounts/signup/">Sign up here …</a></div>
      </div>
    </div>


  </portal>
</template>

<script>
  import {EventBus} from './eventbus.js';

  export default {
    name: 'login-button',
    props: [
      'credits',
    ],
    data() {
      return {
        formVisible: false,
      }
    },
    computed: {
      csrftoken() {
        return this.$csrftoken;
      },
      loggedin() {
        return this.$loggedin;
      },
      //credits () {
      //  return this.$credits;
      //},
      creditTitle() {
        return this.credits + ' tokens left for downloads';
      },
      digits: function () {
        let cred_str = this.credits.toString();
        let res = [];
        for (let digit of cred_str) {
          res.push('/static/images/%23IB%20Website%20' + digit + '.svg');
        }
        return res;
      },
    },
    methods: {
      onLoginClick() {
        this.formVisible = !this.formVisible;
      }
    }
  }
</script>

<style scoped>
  #login-form {
    text-align: left;
    -webkit-box-shadow: -10px 10px 31px -5px rgba(0, 0, 0, 0.67);
    -moz-box-shadow: -10px 10px 31px -5px rgba(0, 0, 0, 0.67);
    box-shadow: -10px 10px 31px -5px rgba(0, 0, 0, 0.67);
    z-index: 2;
    width: 300px;
    top: 70px;
    right: 16px;
    position: absolute;
    height: 200px;
    background-color: rgba(27, 27, 33, 0.90);
  }

  .form-control::-webkit-input-placeholder {
    color: #555;
  }

  /* WebKit, Blink, Edge */
  .form-control:-moz-placeholder {
    color: #555;
  }

  /* Mozilla Firefox 4 to 18 */
  .form-control::-moz-placeholder {
    color: #555;
  }

  /* Mozilla Firefox 19+ */
  .form-control:-ms-input-placeholder {
    color: #555;
  }

  /* Internet Explorer 10-11 */
  .form-control::-ms-input-placeholder {
    color: #555;
  }

  /* Microsoft Edge */

  .login-form-input {
    width: 270px;
    color: #ccc;
    margin: 16px 15px 0 15px;
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 4px solid #555;
    background: none;
  }

  .login-form-input:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: 4px solid #ccc;
  }

  #login-submit {
    cursor: pointer;
    margin-left: 195px;
    margin-top: 10px;
    background: none;
    border: none;
  }

  #login-submit img {
    height: 28px;
    opacity: 0.8;
  }

  #login-submit:hover img {
    height: 28px;
    opacity: 1.0;
    -webkit-filter: drop-shadow(0px 0px 3px #00ffff);
    filter: drop-shadow(0px 0px 3px #00ffff);
  }
</style>